import React from 'react';
import classNames from 'classnames';
import Image from '../../elements/Image';

interface LogoITSMBadgeProps {
  className?: string;
  width?: number;
  height?: number;
}

const defaultProps: LogoITSMBadgeProps = {
  width: 170,
  height: 60,
};

const LogoITSMBadge: React.FC<LogoITSMBadgeProps> = ({ className, width, height, ...props }) => {
  const classes = classNames('brand', className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Image
          src={require('../../../assets/images/ITSM_badge.png')}
          alt="ITSM"
          width={width}
          height={height}
        />
      </h1>
    </div>
  );
};

LogoITSMBadge.defaultProps = defaultProps;

export default LogoITSMBadge;
