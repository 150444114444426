import React, { useState, useEffect, useImperativeHandle } from 'react';
import { throttle } from 'lodash';

export interface ScrollRevealProps {
  ref: any;
  init?: () => void;
  // eslint-disable-next-line no-undef
  children?: () => JSX.Element;
}

const ScrollReveal: React.FC<ScrollRevealProps> = React.forwardRef((props, ref) => {
  const [viewportHeight, setViewPortHeight] = useState(0);
  // eslint-disable-next-line no-undef
  const [revealEl, setRevealEl] = useState<NodeListOf<Element> | []>([]);

  const checkComplete = () => revealEl.length <= document.querySelectorAll('[class*=reveal-].is-revealed').length;

  const elementIsVisible = (el: Element, offset: number) => el.getBoundingClientRect().top <= viewportHeight - offset;

  const revealElements = () => {
    if (checkComplete()) return;
    for (let i = 0; i < revealEl.length; i++) {
      const el: Element = revealEl[i];
      const revealDelay = Number(el.getAttribute('data-reveal-delay'));
      const revealOffset = Number(el.getAttribute('data-reveal-offset')) || 200;
      const listenedEl = el.getAttribute('data-reveal-container')
        ? el.closest(el.getAttribute('data-reveal-container') || '')
        : el;
      if (listenedEl && elementIsVisible(listenedEl, revealOffset) && !el.classList.contains('is-revealed')) {
        if (revealDelay && revealDelay !== 0) {
          setTimeout( () => {
            el.classList.add('is-revealed');
          }, revealDelay);
        } else {
          el.classList.add('is-revealed');
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({
    init() {
      setRevealEl(document.querySelectorAll('[class*=reveal-]'));
    },
  }));

  useEffect(() => {
    if (typeof revealEl !== 'undefined' && revealEl.length > 0) {
      if (!checkComplete()) {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
      }
      revealElements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revealEl]);

  const handleListeners = () => {
    if (!checkComplete()) return;
    window.removeEventListener('scroll', handleScroll);
    window.removeEventListener('resize', handleResize);
  };

  const handleScroll = throttle(() => {
    handleListeners();
    revealElements();
  }, 30);

  const handleResize = throttle(() => {
    setViewPortHeight(window.innerHeight);
  }, 30);

  useEffect(() => {
    handleResize();
    handleListeners();
    revealElements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportHeight]);

  return <>{props.children ? props.children() : null}</>;
});

export default ScrollReveal;
