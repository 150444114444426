import React from 'react';
import Helmet from 'react-helmet';

interface PageMetaDataProps {
  title: string;
  description?: string;
}

const PageMetaData: React.FC<PageMetaDataProps> = ({ title, description }) => (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title} | TeamBit</title>
      {description ? <meta name="description" content={description} /> : null}
    </Helmet>
  );

export default PageMetaData;
