import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import Image from '../../elements/Image';

interface LogoProps {
  className?: string;
  width?: number;
  height?: number;
}

const defaultProps: LogoProps = {
  width: 260,
  height: 54,
};

const Logo: React.FC<LogoProps> = ({ className, width, height, ...props }) => {
  const classes = classNames('brand', className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={require('../../../assets/images/TeamBit-logo-monochrome-light.svg')}
            alt="Open"
            width={width}
            height={height}
          />
        </Link>
      </h1>
    </div>
  );
};

Logo.defaultProps = defaultProps;

export default Logo;
