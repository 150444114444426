import React, { useState, useRef, useEffect } from 'react';

interface ImageSrc {
  default: string;
}

interface ImageProps {
  className?: string;
  src?: ImageSrc | string;
  width?: number;
  height?: number;
  alt?: string;
}

const defaultProps: ImageProps = {
  src: undefined,
  width: undefined,
  height: undefined,
  alt: undefined,
};

const Image: React.FC<ImageProps> = ({ className, src, width, height, alt, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  const image = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    handlePlaceholder(image.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const placeholderSrc = (w: number, h: number) =>
    `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}"%3E%3C/svg%3E`;

  const handlePlaceholder = (imgElement: HTMLImageElement | null) => {
    const placeholder = document.createElement('img');
    const img = imgElement;
    if (!loaded && img) {
      img.style.display = 'none';
      img.before(placeholder);
      placeholder.src = placeholderSrc(Number(img.getAttribute('width')) || 0, Number(img.getAttribute('height')) || 0);
      placeholder.width = Number(img.getAttribute('width'));
      placeholder.height = Number(img.getAttribute('height'));
      placeholder.style.opacity = '0';
      if (img.className) {
        const imageClassNames = img.className.split(' ');
        imageClassNames.forEach(imageClassName => {
          placeholder.classList.add(imageClassName);
        });
      }
      placeholder.remove();
      img.style.display = '';
    }
  };

  function onLoad() {
    setLoaded(true);
  }

  return (
    <img
      {...props}
      ref={image}
      className={className}
      src={typeof src === 'string' ? src : src?.default}
      width={width}
      height={height}
      alt={alt}
      onLoad={onLoad}
    />
  );
};

Image.defaultProps = defaultProps;

export default Image;
