import React from 'react';
import classNames from 'classnames';
import Logo from './partials/Logo';
// import FooterSocial from './partials/FooterSocial';
import { SectionProps } from '../../utils/SectionProps';
import LogoITSMBadge from './partials/LogoITSMBadge';
import LogoPlatinumSolutionPartner from './partials/LogoPlatinumSolutionPartner';
import { Link } from 'gatsby';

interface FooterProps {
  className?: string;
  topOuterDivider?: boolean;
  topDivider?: boolean;
}

const defaultProps = {
  topOuterDivider: SectionProps.defaults.topOuterDivider,
  topDivider: SectionProps.defaults.topDivider,
};

const Footer: React.FC<FooterProps> = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    'site-footer bg-primary-dark',
    topOuterDivider && 'has-top-divider',
    className,
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div className={classNames('site-footer-inner', topDivider && 'has-top-divider')}>
          {/*<div className="footer-top d-flex text-xxs">*/}
          {/*  <FooterSocial className="ml-auto" />*/}
          {/*</div>*/}
          <div className="footer-bottom">
            <div className="footer-blocks mb-0 text-xxs">
              <div className="footer-block mr-auto">
                <div className="d-flex align-items-center mr-24 mb-16 pb-4">
                  <Logo width={156} height={32} className="mr-24" />
                  <LogoPlatinumSolutionPartner />
                  <LogoITSMBadge width={92} />
                </div>
                © 2022 TeamBit | <Link to='/privacy-policy'>Privacy Policy</Link> | <Link to='/candidate-policy'>Candidate Policy</Link>
              </div>
              <div className="footer-block">
                UAB “TeamBit”
                <br />
                Company code: 305954666
                <br />
                Registration address:
                <br />
                K. Barsausko str. 47-13, 51420 Kaunas
              </div>
              <div className="footer-block">
                VAT: LT100014598214
                <br />
                IBAN: LT167300010170393945
                <br />
                AB bank SWEDBANK
                <br />
                SWIFT (BIC) code: HABALT22
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = defaultProps;

export default Footer;
