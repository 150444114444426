import React, { useState, useRef, useEffect, MouseEventHandler, KeyboardEventHandler } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { LinkGetProps } from '@reach/router';
import LogoPlatinumSolutionPartner from './partials/LogoPlatinumSolutionPartner';
import { SectionSharedTypesProps } from '../../utils/SectionProps';
import Logo from './partials/Logo';
import {CookieConsent} from "react-cookie-consent";

interface HeaderProps extends SectionSharedTypesProps {
  className?: string;
  navPosition: string;
  hideNav?: boolean;
}

const defaultProps: HeaderProps = {
  navPosition: '',
  hideNav: false,
};

const navLinks = [
  {
    title: 'Products',
    link: '/products',
  },
  {
    title: 'Services',
    link: '/services',
    partiallyMatchedLink: true,
  },
  {
    title: 'Solutions',
    link: '/solutions',
  },
  {
    title: 'About us',
    link: '/about-us',
  },
  {
    title: 'Career',
    link: '/career',
  },
];

const Header: React.FC<HeaderProps> = ({
  className,
  navPosition,
  hideNav,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [navbarOpacity, setNavbarOpacity] = useState(0);
  const nav = useRef<HTMLHeadingElement | null>(null);
  const hamburger = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (isActive) {
      openMenu();
    }
    toggleScrolled();
    document.addEventListener<'keydown'>('keydown', keyPress as () => void);
    document.addEventListener<'click'>('click', clickOutside as () => void);
    document.addEventListener<'scroll'>('scroll', handleScroll as () => void);
    return () => {
      document.removeEventListener<'keydown'>('keydown', keyPress as () => void);
      document.removeEventListener<'click'>('click', clickOutside as () => void);
      document.removeEventListener<'scroll'>('scroll', handleScroll as () => void);
      closeMenu();
    };
  });

  const handleScroll = () => {
    closeMenu();
    toggleScrolled();
  };

  const toggleScrolled = (): void => {
    const scrollBreak = 100;
    const scrollTop: number =
      window.pageYOffset || window.document.documentElement.scrollTop || window.document.body.scrollTop || 0;

    if (!isScrolled && scrollTop >= scrollBreak) {
      setIsScrolled(true);
      setNavbarOpacity(1);
    } else if (scrollTop < scrollBreak) {
      setIsScrolled(false);
      setNavbarOpacity(scrollTop / scrollBreak);
    }
  };
  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    if (nav.current) {
      nav.current.style.maxHeight = `${nav.current.scrollHeight}px`;
    }
    setIsActive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    if (nav.current) {
      nav.current.style.maxHeight = '';
    }
    setIsActive(false);
  };

  const keyPress: KeyboardEventHandler = e => {
    if (isActive && e.keyCode === 27) {
      closeMenu();
    }
  };

  const clickOutside: MouseEventHandler = e => {
    if (!nav.current) return;
    if (!isActive || nav.current.contains(e.target as HTMLHeadingElement) || e.target === hamburger.current) return;
    closeMenu();
  };

  const isPartiallyCurrentLink = ({ isPartiallyCurrent }: LinkGetProps) =>
    isPartiallyCurrent ? { className: 'active' } : {};

  const isCurrentLink = ({ isCurrent }: LinkGetProps) => (isCurrent ? { className: 'active' } : {});

  const classes = classNames('site-header', bottomOuterDivider && 'has-bottom-divider', className);

  return (
    <header {...props} className={classes}>
      <div style={{ backgroundColor: `rgba(36, 57, 89, ${navbarOpacity})` }}>
        <div className="container">
          <div className={classNames('site-header-inner', bottomDivider && 'has-bottom-divider')}>
            <Logo width={156} height={32} />
            <LogoPlatinumSolutionPartner />
            {!hideNav && (
              <>
                <button ref={hamburger} className="header-nav-toggle" onClick={isActive ? closeMenu : openMenu}>
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner" />
                  </span>
                </button>
                <nav ref={nav} className={classNames('header-nav', isActive && 'is-active')}>
                  <div className="header-nav-inner">
                    <ul className={classNames('list-reset text-xs', navPosition && `header-nav-${navPosition}`)}>
                      {navLinks?.map((navLink, i) => (
                        <li key={i}>
                          <Link
                            to={navLink.link}
                            onClick={closeMenu}
                            activeClassName={'active'}
                            getProps={navLink.partiallyMatchedLink ? isPartiallyCurrentLink : isCurrentLink}
                          >
                            {navLink.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <ul className="list-reset header-nav-right">
                      <li>
                        <Link
                          to="/contact-us"
                          onClick={closeMenu}
                          className="button button-primary button-wide-mobile button-sm"
                        >
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </>
            )}
          </div>
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Ok, I understand"
        style={{
          background: "#2B373B",
          fontSize: "16px",
          opacity: "0.8",
        }}
        expires={150}
      >
        We use cookies that ensure that you will be comfortable using the website. If you continue to browse our website, it is equivalent to your consent to the use of cookies. <a style={{textDecorationLine: 'underline'}} href="/privacy-policy">Privacy Policy</a>{" "}
      </CookieConsent>
    </header>
  );
};

Header.defaultProps = defaultProps;

export default Header;
