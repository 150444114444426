import React, { useEffect, useRef } from 'react';
import BodyClassName from 'react-body-classname';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import '../assets/scss/style.scss';
import ScrollReveal, { ScrollRevealProps } from '../utils/ScrollReveal';

const LayoutDefault: React.FC = ({ children }) => {
  const childRef = useRef<ScrollRevealProps>();
  const url = typeof window !== 'undefined' ? window.location.href : '';

  useEffect(() => {
    const refInit = childRef.current?.init;
    document.body.classList.add('is-loaded');
    if (refInit) {
      refInit();
    }
  }, [url]);

  return (
    <BodyClassName className="has-animations">
      <ScrollReveal
        ref={childRef}
        children={() => (
          <>
            <Header navPosition="right" />
            <main className="site-content">{children}</main>
            <Footer />
          </>
        )}
      />
    </BodyClassName>
  );
};

export default LayoutDefault;
