// Props shared by all sections

export interface SectionSharedTypesProps {
  topOuterDivider?: boolean;
  bottomOuterDivider?: boolean;
  topDivider?: boolean;
  bottomDivider?: boolean;
  hasBgColor?: boolean;
  invertColor?: boolean;
}

interface SectionSharedProps {
  defaults: SectionSharedTypesProps;
}

const SectionShared: SectionSharedProps = {
  defaults: {
    topOuterDivider: false,
    bottomOuterDivider: false,
    topDivider: false,
    bottomDivider: false,
    hasBgColor: false,
    invertColor: false,
  },
};

// Default section props
export const SectionProps: SectionSharedProps = {
  defaults: {
    ...SectionShared.defaults,
  },
};

// Split props
export interface SplitTypesProps {
  invertMobile?: boolean;
  invertDesktop?: boolean;
  alignTop?: boolean;
  imageFill?: boolean;
}

interface SplitProps {
  defaults: SplitTypesProps;
}

export const Split: SectionSplitProps = {
  defaults: {
    invertMobile: false,
    invertDesktop: false,
    alignTop: false,
    imageFill: false,
  },
};

// Section split props
export interface SectionSplitTypesProps extends SectionSharedTypesProps, SplitTypesProps {}

interface SectionSplitProps {
  defaults: SectionSplitTypesProps;
}

export const SectionSplit: SectionSplitProps = {
  defaults: {
    ...SectionShared.defaults,
    invertMobile: false,
    invertDesktop: false,
    alignTop: false,
    imageFill: false,
  },
};

// Section tiles props
export interface SectionTilesTypesProps extends SectionSharedTypesProps {
  pushLeft?: boolean;
}

interface SectionTilesProps {
  defaults: SectionTilesTypesProps;
}

export const SectionTiles: SectionTilesProps = {
  defaults: {
    ...SectionShared.defaults,
    pushLeft: false,
  },
};
