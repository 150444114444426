import React from 'react';
import classNames from 'classnames';
import Image from '../../elements/Image';

interface LogoPlatinumSolutionPartnerProps {
  className?: string;
  width?: number;
  height?: number;
}

const defaultProps: LogoPlatinumSolutionPartnerProps = {
  width: 170,
  height: 60,
};

const LogoPlatinumSolutionPartner: React.FC<LogoPlatinumSolutionPartnerProps> = ({ className, width, height, ...props }) => {
  const classes = classNames('brand', className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Image
          src={require('../../../assets/images/platinum-solution-partner-white.png')}
          alt="Open"
          width={width}
          height={height}
        />
      </h1>
    </div>
  );
};

LogoPlatinumSolutionPartner.defaultProps = defaultProps;

export default LogoPlatinumSolutionPartner;
